.chart-section-container{
    @apply flex w-full h-fit flex-col gap-2 
}

.chart-section-header{
    @apply flex justify-between items-center px-5
}

.chart-container{
    @apply w-full h-full border rounded-md shadow-md
}