.layout{
    @apply w-screen h-screen flex flex-col overflow-x-hidden;
}

.navbar-parent{
    @apply flex w-full h-fit items-center justify-center flex-col;
}

.outlet-parent{
    @apply flex w-full h-full;
}
