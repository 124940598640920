.dashboardcard-container{
    @apply w-full h-fit grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1  mt-24 md:mt-0 gap-3 
}

.dashboard-card-container{
    @apply flex w-full h-[130px] justify-center items-center border gap-5 rounded-md p-3 shadow-lg
}

.dashboard-card-icon{
    @apply flex w-[40px] h-[40px] rounded-full justify-center items-center p-1
}

.dashboard-card-content{
    @apply flex justify-center items-start flex-col text-white
}

.dashboard-card-value{
    @apply text-xl font-bold
}

.dashboard-card-text{
    @apply text-sm font-medium
}