.todays-appointment-container{
    @apply flex w-full h-fit flex-col gap-2
}

.todays-appointment-header{
    @apply flex justify-between items-center
}

.ta-header-text{
    @apply flex font-semibold
}

.ta-header-link{
    @apply flex text-blue-400 items-center gap-2 cursor-pointer hover:text-blue-500 transition
}

.ta-users-list{
    @apply flex flex-col w-full rounded-lg shadow-md h-[222px] overflow-y-auto border pt-2 gap-1
}

.ta-user-container{
    @apply flex w-full h-fit hover:bg-blue-100 items-center gap-2 px-3 cursor-pointer transition duration-300
}

.ta-user-profile{
    @apply flex w-[50px] h-[50px] p-1
}

.ta-user-profile-image{
    @apply rounded-full overflow-hidden
}

.ta-user-detail-container{
    @apply flex w-full h-full justify-between items-center
}

.ta-user-content{
    @apply flex flex-col w-full h-full justify-center
}