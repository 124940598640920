.welcomebox-container{
    @apply w-full h-[200px] shadow-lg relative lg:mt-10 rounded-lg
}

.welcomebox-content{
    @apply w-full h-full flex flex-col justify-center items-start gap-2  p-10
}

.welcome-text{
    @apply font-black text-xl md:text-2xl lg:text-3xl xl:text-5xl text-primary
}

.welcome-text-two{
    @apply font-semibold text-base md:text-lg lg:text-xl xl:text-2xl capitalize text-secondary
}


.welcomebox-image{
    @apply absolute hidden lg:flex bottom-0 right-16 mb-2
}