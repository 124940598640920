.patients-container{
    @apply flex w-full h-fit flex-col gap-2
}

.patients-header{
    @apply flex justify-between items-center px-5
}

.p-header-text{
    @apply flex font-semibold
}

.p-value-card-container{
    @apply flex w-full rounded-lg shadow-md h-[100px] border py-5 
}

.p-value-card{
    @apply flex w-full  justify-around items-center
}

.p-value-icon-container{
    @apply flex h-full justify-center gap-5 items-center 
}

.p-value-icon{
    @apply flex w-[53px] h-[53px] justify-center items-center rounded-full bg-[#5694FB]
}

.p-values-container{
    @apply flex flex-col justify-center items-center  h-full
}

.p-value{
    @apply font-bold text-lg
}

.p-value-text{
    @apply text-sm font-semibold
}

.p-value-growth-container{
    @apply flex items-start justify-end  h-full 
}

.p-growth-value{
    @apply text-sm text-blue-400 w-fit flex h-fit gap-1 items-center 
}