.appointment-requests-container{
    @apply flex w-full h-fit flex-col gap-2
}

.ar-header{
    @apply flex justify-between items-center
}

.ar-header-text{
    @apply flex font-semibold
}

.ar-header-link{
    @apply flex text-blue-400 items-center gap-2 cursor-pointer hover:text-blue-500 transition
}

.ar-users-list{
    @apply flex flex-col w-full rounded-lg shadow-md h-[400px] overflow-y-auto border pt-2 gap-1
}

.ar-user-container{
    @apply flex w-full h-fit items-center gap-2 px-3
}

.ar-user-profile-image{
    @apply flex w-[50px] h-[50px] p-1
}

.ar-user-profile{
    @apply rounded-full overflow-hidden
}

.ar-user-details-container{
    @apply flex w-full h-full justify-between items-center
}

.ar-user-details-text{
    @apply flex flex-col w-full h-full justify-center
}

.ar-status-buttons{
    @apply text-xs font-semibold w-full flex justify-end
}

.ar-user-status-button {
    @apply w-1/2 border rounded-md p-1 shadow-lg h-fit justify-center items-center
}

.ar-user-update-buttons{
    @apply flex text-lg items-center gap-3
}

.update-button{
    @apply w-fit border rounded-md p-1  shadow-lg h-fit justify-center items-center active:bg-slate-200
}