.sideicons-container{
    @apply md:flex hidden flex-col gap-3 absolute -top-1 -right-3 rounded-md
}

.sideicon{
    @apply w-8 rounded-md bg-gray-300
}

.sideicon-image{
    @apply rounded-md overflow-hidden -translate-y-1 translate-x-1 cursor-pointer transition duration-500  hover:translate-y-0 hover:translate-x-0
}

.bottomicons-container{
    @apply  md:hidden flex w-full h-fit  mt-5 justify-center gap-16 items-center p-3
}

.bottomicon{
    @apply w-8 rounded-md bg-gray-300
}

.bottomicon-image{
    @apply translate-x-1 rounded-md cursor-pointer transition duration-500 -translate-y-1 hover:translate-x-0 hover:-translate-y-0
}