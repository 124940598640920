.earnings{
    @apply relative w-fit h-fit hidden md:flex
}

.earnings-content{
    @apply flex absolute inset-0 justify-start items-center gap-3 px-3
}

.lock-icon{
    @apply flex w-[39px] h-[39px] justify-center items-center rounded-full bg-[#3372AC]
}

.text-content{
    @apply flex flex-col text-white justify-start
}

.text-content > div:first-child{
    @apply flex font-bold 
}

.text-content > div:nth-child(2){
    @apply flex font-normal text-xs 
}